import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Testing the MQTT and HTTP Alarmserver with MQTT (Deprecated v1)",
  "path": "/Frequently_Asked_Question/WQHD_MQTTv5_Alarmserver_Testing_Deprecated_v1/",
  "dateChanged": "2023-03-26",
  "author": "Mike Polinowski",
  "excerpt": "Is it possible to trigger this alarmserver test via the MQTT interface?",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='Testing the MQTT and HTTP Alarmserver with MQTT' dateChanged='2022-11-30' author='Mike Polinowski' tag='INSTAR IP Camera' description='Is it possible to trigger this alarmserver test via the MQTT interface?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/WQHD_MQTTv5_Alarmserver_Testing_Deprecated_v1/' locationFR='/fr/Frequently_Asked_Question/WQHD_MQTTv5_Alarmserver_Testing_Deprecated_v1/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "testing-the-mqtt-and-http-alarmserver-with-mqtt",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#testing-the-mqtt-and-http-alarmserver-with-mqtt",
        "aria-label": "testing the mqtt and http alarmserver with mqtt permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Testing the MQTT and HTTP Alarmserver with MQTT`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: I read `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/Alarmserver_Testing/"
      }}>{`this article`}</a>{` showing how to test my IN-9408 2k+ WQHD cameras `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Smarthome/Alarm_Server/"
      }}>{`alarmserver`}</a>{`. Is it possible to trigger this alarmserver test via the MQTT interface? I am especially interested in having a MQTT topic I can update to have my cameras contact an HTTP webhook provided by my home automation system.`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: Both - the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Smarthome/Alarm_Server/"
      }}>{`HTTP`}</a>{` and `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Smarthome/MQTT/"
      }}>{`MQTT`}</a>{` Alarmserver - can be tested via MQTT. The corresponding `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/MQTTv5_API/"
      }}>{`MQTT topics`}</a>{` are:`}</p>
    <blockquote>
      <p parentName="blockquote">{`This article is written for the `}<strong parentName="p">{`deprecated version 1`}</strong>{` of the alarmserver API. Please use the `}<a parentName="p" {...{
          "href": "/en/Frequently_Asked_Question/WQHD_MQTTv5_Alarmserver_Testing_APIv2/"
        }}>{`Alarm Server API v2`}</a>{` instead.`}</p>
    </blockquote>
    <h2 {...{
      "id": "http-alarmserver",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#http-alarmserver",
        "aria-label": "http alarmserver permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HTTP Alarmserver`}</h2>
    <h3 {...{
      "id": "alarm-trigger",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#alarm-trigger",
        "aria-label": "alarm trigger permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Trigger`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`smarthome/alarmserver/test/http/trigger`}</code></li>
    </ul>
    <p>{`Update the `}<strong parentName="p">{`Trigger Topic`}</strong>{` with the payload value `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{` - `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`10`}</code>{` (or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`99`}</code>{`), e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"1"}`}</code>{`:`}</p>
    <ul>
      <li parentName="ul">{`Alarm Area 1 triggered: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&trigger=1`}</code></li>
      <li parentName="ul">{`Alarm Area 2 triggered: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&trigger=2`}</code></li>
      <li parentName="ul">{`Alarm Area 3 triggered: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&trigger=3`}</code></li>
      <li parentName="ul">{`Alarm Area 4 triggered: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&trigger=4`}</code></li>
      <li parentName="ul">{`Alarm-In / PIR triggered: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&trigger=5`}</code></li>
      <li parentName="ul">{`Audio Alarm triggered: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&trigger=6`}</code></li>
      <li parentName="ul">{`PIR triggered & Alarm Area 1 triggered: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&trigger=7`}</code></li>
      <li parentName="ul">{`PIR triggered & Alarm Area 2 triggered: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&trigger=8`}</code></li>
      <li parentName="ul">{`PIR triggered & Alarm Area 3 triggered: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&trigger=9`}</code></li>
      <li parentName="ul">{`PIR triggered & Alarm Area 4 triggered: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&trigger=10`}</code></li>
      <li parentName="ul">{`Manual trigger (cmd=pushhostalarm): `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&trigger=99`}</code></li>
    </ul>
    <p>{`And your camera will contact the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Smarthome/Alarm_Server/"
      }}>{`configured alarmserver`}</a>{` with the corresponding URL queries, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`&object=0&active=1`}</code>{` for the example chosen above.`}</p>
    <h3 {...{
      "id": "detected-object",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#detected-object",
        "aria-label": "detected object permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Detected Object`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`smarthome/alarmserver/test/http/object`}</code></li>
    </ul>
    <p>{`Update the `}<strong parentName="p">{`Object Topic`}</strong>{` with the payload value `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{` - `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`16`}</code>{`, e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"1"}`}</code>{`:`}</p>
    <ul>
      <li parentName="ul">{`Person: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&object=1`}</code></li>
      <li parentName="ul">{`Vehicle: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&object=2`}</code></li>
      <li parentName="ul">{`Person + Vehicle: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&object=3`}</code></li>
      <li parentName="ul">{`Animal: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&object=4`}</code></li>
      <li parentName="ul">{`Person + Animal: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&object=5`}</code></li>
      <li parentName="ul">{`Animal + Vehicle: `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`&object=6`}</code></li>
    </ul>
    <p>{`And your camera will contact the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1440p_Series/Smarthome/Alarm_Server/"
      }}>{`configured alarmserver`}</a>{` with the corresponding URL queries, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`&active=0&object=1`}</code>{` for the example chosen above.`}</p>
    <h2 {...{
      "id": "mqtt-alarmserver",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#mqtt-alarmserver",
        "aria-label": "mqtt alarmserver permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`MQTT Alarmserver`}</h2>
    <h3 {...{
      "id": "alarm-trigger-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#alarm-trigger-1",
        "aria-label": "alarm trigger 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Trigger`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`smarthome/alarmserver/test/mqtt/trigger`}</code></li>
    </ul>
    <p>{`Update the `}<strong parentName="p">{`Trigger Topic`}</strong>{` with the payload value `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{` - `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`10`}</code>{` (or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`99`}</code>{`), e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"1"}`}</code>{`:`}</p>
    <ul>
      <li parentName="ul">{`Alarm Area 1 triggered: -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"1"}`}</code></li>
      <li parentName="ul">{`Alarm Area 2 triggered: -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"2"}`}</code></li>
      <li parentName="ul">{`Alarm Area 3 triggered: -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"3"}`}</code></li>
      <li parentName="ul">{`Alarm Area 4 triggered: -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"4"}`}</code></li>
      <li parentName="ul">{`Alarm-In / PIR triggered: -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"5"}`}</code></li>
      <li parentName="ul">{`Audio Alarm triggered: -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"6"}`}</code></li>
      <li parentName="ul">{`PIR triggered & Alarm Area 1 triggered: -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"7"}`}</code></li>
      <li parentName="ul">{`PIR triggered & Alarm Area 2 triggered: -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"8"}`}</code></li>
      <li parentName="ul">{`PIR triggered & Alarm Area 3 triggered: -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"9"}`}</code></li>
      <li parentName="ul">{`PIR triggered & Alarm Area 4 triggered:  -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"10"}`}</code></li>
      <li parentName="ul">{`Manual trigger (cmd=pushhostalarm): -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"99"}`}</code></li>
    </ul>
    <p>{`And your camera will update the MQTT alarmserver topic `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`status/alarm/triggered`}</code>{` with the corresponding payload - `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"1"}`}</code>{` for the example above.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/aab7259ab75acf1fdd8a6fe921685753/44a54/AS_Test_MQTT_Trigger.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "67.82608695652173%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACJElEQVQoz4WR2W7TQABF5zuQKFVjZ2vs8b7NjLdZ7Dh2UqdNSekWUnip1CIoL7zzNZSltP1AcAqCtkgcHV3Ny9Wd0YCITyw/Nf1UMbFqEsXAivlLG1GXcMUk0CbQDldJVKsR2kQxQ5BMP+fzq+rwdnRwTXe+bS9vtxY31eF1dXg92v9evLgaH92g8hKVn1B1GZSX6fbX4fwqmX55/f4GjHc+5OMLl7x0ydIlS5S+akwa8ergRydJdsbLt0l2lmRnaX5Oh+dpfr41ewdYikYFNY1NyxwYWg+qHVVpP5CmaDhM4shNYi+J/d8GwPX8o+PFZLIlRJ5lOcYh1HRNN/6oGZyLup5OJnVdT6tqXFVVXVdlOQS+78/ne5yLMAwppUEQQAi1+6RpWlXVaDQSWXmyXOwdvMnrj/n4AjiOPZvtcEajiDBGMcYPyrquI4w83/Y8E2oWQr4fxJqVW64AumHN9k6ns9OsWGAS27Z1fxUauuEHTre3Icvr7c56S1qT5KeS/GQwkIDjuLvP94tiQkLqut6jO0PDMALkbQ7kTrfV7bV6PanXlzrdlqL2QVEUu7szzpkQnDH2oAzhXdnt9jck+ZncXpfbTbaktf6gAzjnURRhjOM49rzHy82bMUGuZzluY4A8z3cC5BGCgKIoqqpCCO9S+xdJkgohkhVZlnHOxQqg3+dxU9f1KIrKshRCMMYoo00wxjkH2v9ovgqhpkZp/Bc/l38AGj/LWcIan7sAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/aab7259ab75acf1fdd8a6fe921685753/e4706/AS_Test_MQTT_Trigger.avif 230w", "/en/static/aab7259ab75acf1fdd8a6fe921685753/d1af7/AS_Test_MQTT_Trigger.avif 460w", "/en/static/aab7259ab75acf1fdd8a6fe921685753/7f308/AS_Test_MQTT_Trigger.avif 920w", "/en/static/aab7259ab75acf1fdd8a6fe921685753/33c9a/AS_Test_MQTT_Trigger.avif 1017w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/aab7259ab75acf1fdd8a6fe921685753/a0b58/AS_Test_MQTT_Trigger.webp 230w", "/en/static/aab7259ab75acf1fdd8a6fe921685753/bc10c/AS_Test_MQTT_Trigger.webp 460w", "/en/static/aab7259ab75acf1fdd8a6fe921685753/966d8/AS_Test_MQTT_Trigger.webp 920w", "/en/static/aab7259ab75acf1fdd8a6fe921685753/11ae3/AS_Test_MQTT_Trigger.webp 1017w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/aab7259ab75acf1fdd8a6fe921685753/81c8e/AS_Test_MQTT_Trigger.png 230w", "/en/static/aab7259ab75acf1fdd8a6fe921685753/08a84/AS_Test_MQTT_Trigger.png 460w", "/en/static/aab7259ab75acf1fdd8a6fe921685753/c0255/AS_Test_MQTT_Trigger.png 920w", "/en/static/aab7259ab75acf1fdd8a6fe921685753/44a54/AS_Test_MQTT_Trigger.png 1017w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/aab7259ab75acf1fdd8a6fe921685753/c0255/AS_Test_MQTT_Trigger.png",
              "alt": "Testing the MQTT and HTTP Alarmserver with MQTT",
              "title": "Testing the MQTT and HTTP Alarmserver with MQTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "detected-object-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#detected-object-1",
        "aria-label": "detected object 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Detected Object`}</h3>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`smarthome/alarmserver/test/mqtt/object`}</code></li>
    </ul>
    <p>{`Update the `}<strong parentName="p">{`Object Topic`}</strong>{` with the payload value `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{` - `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`16`}</code>{`, e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"1"}`}</code>{`:`}</p>
    <ul>
      <li parentName="ul">{`Person: -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"1"}`}</code></li>
      <li parentName="ul">{`Vehicle: -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"2"}`}</code></li>
      <li parentName="ul">{`Person + Vehicle: -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"3"}`}</code></li>
      <li parentName="ul">{`Animal: -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"4"}`}</code></li>
      <li parentName="ul">{`Person + Animal: -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"5"}`}</code></li>
      <li parentName="ul">{`Animal + Vehicle: -> `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"6"}`}</code></li>
    </ul>
    <p>{`And your camera will update the MQTT alarmserver topic `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`status/alarm/triggered/object`}</code>{` with the corresponding payload - `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"1"}`}</code>{` for the example above.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/af4437b94eda40bf5178990b0e5bb402/51ed8/AS_Test_MQTT_Object.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.60869565217392%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACPklEQVQoz4WRb0vbQACH73MMnKJp2s4ml6Rpk7v8ucvlf5pLE9c2bakUdPXFBhW2d3sz2KcZTnTqFxytKGoHe3g4yIuHH3cBPeRpiEoaljTU0XBHRR0NHaumZhLkhrJuSRqSu/jZjoalzWnJug2iyQ0/veen9/7oZri8Pzl74Is7vrjLF3/S6U25fCDlbzT4ZfErnF/R6jqd3ZLyenl5d3J2C4rRd0RXhntmOOeWt7LZheWtNrILi11g+skNv4T5Vy9Ze8mapesgu/TidTH6FuVrUA4T01D0bkfXjhW5JUviS6VOw7H1QeZ7FHkUsa0exZ5nO3YPTGfz8biOoiSKE89jqtZVVO1ZCFXXpWVZFcOqqqpiOMxzXpV8WAwIoWA8Huc5J4RSSgkhymsghLZtc87zPI+TfD6fr1aXSfkjH/103AAUBc+yyHEwY2w3VhQFIYSwYaKurhv9ft92iKKHih4FYQwG+Uk9/xynNSHEMIy3KVQQNiW5KTT2xeZBQ9wXGu9FcU8Q3k1nNSiruvp4TrwCIayq6m6MMYJKu9k6bLWPWm2h/UFotQWxeTipJ2A6rZMkDEKWpmmv14MQvsmxhSS4WW6Im+WtB4dHe3U9AYwx23Zcl9i2vVtCCDHGJur3+t2+oWPLRNjA2EDYmM1mQJZlCKG8Zfe1IISO4yRJwhijlIYboiiKfN9fLBbgH/fcWU7TNI5j/4kgCHzf55wD5X+Ypun7/uMae+Lx81Wsbtn9z3EcZ1kWvSBN0yAI/gJWx9rrzxQNLgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/af4437b94eda40bf5178990b0e5bb402/e4706/AS_Test_MQTT_Object.avif 230w", "/en/static/af4437b94eda40bf5178990b0e5bb402/d1af7/AS_Test_MQTT_Object.avif 460w", "/en/static/af4437b94eda40bf5178990b0e5bb402/7f308/AS_Test_MQTT_Object.avif 920w", "/en/static/af4437b94eda40bf5178990b0e5bb402/14e47/AS_Test_MQTT_Object.avif 1021w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/af4437b94eda40bf5178990b0e5bb402/a0b58/AS_Test_MQTT_Object.webp 230w", "/en/static/af4437b94eda40bf5178990b0e5bb402/bc10c/AS_Test_MQTT_Object.webp 460w", "/en/static/af4437b94eda40bf5178990b0e5bb402/966d8/AS_Test_MQTT_Object.webp 920w", "/en/static/af4437b94eda40bf5178990b0e5bb402/11b7f/AS_Test_MQTT_Object.webp 1021w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/af4437b94eda40bf5178990b0e5bb402/81c8e/AS_Test_MQTT_Object.png 230w", "/en/static/af4437b94eda40bf5178990b0e5bb402/08a84/AS_Test_MQTT_Object.png 460w", "/en/static/af4437b94eda40bf5178990b0e5bb402/c0255/AS_Test_MQTT_Object.png 920w", "/en/static/af4437b94eda40bf5178990b0e5bb402/51ed8/AS_Test_MQTT_Object.png 1021w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/af4437b94eda40bf5178990b0e5bb402/c0255/AS_Test_MQTT_Object.png",
              "alt": "Testing the MQTT and HTTP Alarmserver with MQTT",
              "title": "Testing the MQTT and HTTP Alarmserver with MQTT",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      